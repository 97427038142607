<template>
  <v-container>
    <div class="header-bar">
      <UserCount></UserCount>
      <UserProfile></UserProfile>
    </div>
  </v-container>
</template>

<script>
import UserCount from './UserCount'
import UserProfile from './UserProfile'
export default {
  name: 'TheHeader',
  components: { UserProfile, UserCount }
}
</script>

<style lang="scss">
.header-bar {
  width: 100%;
  padding: 1rem;
  height: 80px;
  display: flex;
  justify-content: space-between;
}
</style>
