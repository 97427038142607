<template>
  <div class="circle">
      <v-btn :class="{'shadow': isSpeaking}" fab v-on="handlers" class="circle__btn">
        <v-icon class="pause" name="pause">mdi-microphone</v-icon>
        <v-icon color="white" class="play" name="play">mdi-microphone</v-icon>
      </v-btn>
    <i :class="{'paused': isSpeaking}" class="circle__back-1"></i>
    <i :class="{'paused': isSpeaking}" class="circle__back-2"></i>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'Speak',
  data () {
    const vm = this
    return {
      isSpeaking: false,
      handlers: {
        mousedown: vm.speak,
        mouseup: vm.speak,
        touchstart: vm.speak,
        touchend: vm.speak
      }
    }
  },
  watch: {
    isSpeaking () {
      this.toggleTrack('video')
      this.toggleTrack('audio')
    }
  },
  mounted () {
    // window.addEventListener('beforeunload', this.closeOffer)
  },
  methods: {
    ...mapActions(['toggleTrack']),
    speak (e) {
      e.preventDefault()
      this.isSpeaking = !this.isSpeaking
    }
  }
}
</script>

<style lang="scss" scoped>
.circle {
  border-radius: 1rem;
  width: 200px;
  height: 200px;

  &__btn {
    width: 4rem;
    height: 4rem;
    display: flex;
    z-index: 1000;
    background: $grey-light !important;
    box-shadow: $shadow-1 !important;
    cursor: pointer;
    &.shadow {box-shadow: $inner-shadow-1 !important;}

    &:active {
      background: $grey-light !important;
    }

    .play {
      position: absolute;
      opacity: 0;
      transition: all .2s linear;
      &.visibility {
        opacity: 1;
      }
    }
    .pause {
      position: absolute;
      transition: all .2s linear;
      &.visibility {
        opacity: 0;
      }
    }
  }
  &__back-1, &__back-2 {
    pointer-events: none;
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    filter: blur(1px);
    z-index: 10;
    visibility: hidden;
  }

  &__back-1 {
    box-shadow: .4rem .4rem .8rem #{$grey-dark},
    -.4rem -.4rem .8rem #{$white};
    background: linear-gradient(to bottom right, #{$grey-dark} 0%, #{$white} 100%);
    animation: waves 2s linear infinite;

    &.paused {
      animation-play-state: running;
      visibility: visible;
    }
  }

  &__back-2 {
    box-shadow: .4rem .4rem .8rem #{$grey-dark},
    -.4rem -.4rem .8rem #{$white};
    animation: waves 4s linear 2s infinite;

    &.paused {
      animation-play-state: running;
      visibility: visible;
    }
  }
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
