<template>
  <div class="current-user-box current-user-box--inner">
    <v-icon small class="current-user-box__profile-icon" :style="dropShadow" :color="getCurrentUser.userColor">mdi-checkbox-blank-circle</v-icon>
    <v-icon>mdi-account-circle</v-icon>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfile',
  computed: {
    ...mapGetters(['getCurrentUser']),
    dropShadow () {
      return `filter: drop-shadow(0px 0px 4px ${this.getCurrentUser.userColor})`
    }
  }
}
</script>

<style lang="scss" scoped>
.current-user-box {
  @include info-box;
  height: fit-content;
  font-weight: bold;
}
</style>
