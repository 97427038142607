<template>
  <div class="neighbor">
    <video @click="showBiggerVideo" :muted="name === 'YOU'" class="videoStream" autoplay playsinline :id="'stream-' + stream.id"></video>
    <div v-show="showVideoDialog" class="videoDialog">
        <video :muted="name === 'YOU'" class="videoStream__fullscreen" autoplay playsinline :id="'stream-full-' + stream.id"></video>
        <v-btn
          color="pink"
          dark
          absolute
          top
          right
          small
          fab
          @click="showBiggerVideo"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Neighbor',
  data () {
    return {
      userActive: false,
      showVideoDialog: false
    }
  },
  props: {
    stream: {
      required: true
    },
    name: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    dropShadow () {
      return `filter: drop-shadow(0px 0px 4px ${this.getCurrentUser.userColor})`
    }
  },
  mounted () {
    document.getElementById('stream-' + this.stream.id).srcObject = this.stream
  },
  methods: {
    showBiggerVideo () {
      document.getElementById('stream-' + this.stream.id).srcObject = this.showVideoDialog ? this.stream : null
      document.getElementById('stream-full-' + this.stream.id).srcObject = this.showVideoDialog ? null : this.stream
      this.showVideoDialog = !this.showVideoDialog
    }
  }
}
</script>

<style lang="scss" scoped>
.videoStream {
  width: 5vh;
  height: 5vh;
  border-radius: 2.5vh;
  object-fit: cover;
  box-shadow: $shadow-2;
}

.videoDialog {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: 1rem;

  .v-card__text {
    margin: 0;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
