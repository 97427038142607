import { db, rooms, users } from '../../../firebase'
import { getRandomColor } from '../../utils'
import { EventBus } from '../../event-bus'
import { doc, addDoc, setDoc, deleteDoc, getDoc, updateDoc, onSnapshot, collection } from 'firebase/firestore'

const servers = {
  iceServers: [
    {
      urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302']
    }
  ],
  iceCandidatePoolSize: 10
}
const pc = new RTCPeerConnection(servers)
let localStream
let remoteStream
let sender

const defaultState = {
  currentUser: {
    userColor: '',
    offer: '',
    id: ''
  },
  users: [],
  roomID: '',
  offerCreated: false
}

const getters = {
  localStream: state => state.localStream,
  remoteStream: state => state.remoteStream,
  myID: state => state.myID,
  allUsers: state => state.users,
  usersLength: state => state.users.length,
  getCurrentUser: state => state.currentUser,
  offerCreated: state => state.offerCreated,
  roomID: state => state.roomID
}

const actions = {
  async setup ({ commit, state }) {
    // // Setup Streams
    localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
    remoteStream = new MediaStream()

    EventBus.$emit('SET_STREAM', {
      source: localStream,
      name: 'YOU',
      id: localStream.id
    })

    // Push tracks from local stream to peer connection
    localStream.getTracks().forEach((track) => {
      track.enabled = false
      sender = pc.addTrack(track, localStream)
    })

    // Pull tracks from remote stream, add to video stream
    pc.ontrack = (event) => {
      event.streams[0].getTracks().forEach((track) => {
        remoteStream.addTrack(track)
      })
    }

    if (!state.currentUser || !state.currentUser.id) {
      const userColor = getRandomColor()
      const myUserProfile = await addDoc(users, { userColor: userColor })
      commit('setCurrentUser', {
        userColor: userColor,
        id: myUserProfile.id
      })
    }
  },
  async disconnect ({ state, dispatch }) {
    if (state.roomID) {
      await deleteDoc(doc(db, 'rooms', state.roomID))
      pc.removeTrack(sender)
    }
  },
  async makeOffer ({ commit, dispatch, state }) {
    // Get candidates for roomer, save to db
    const room = await doc(rooms)
    commit('setRoomID', room.id)
    const offerCandidates = collection(room, 'offerCandidate')
    const answerCandidates = collection(room, 'answerCandidate')

    pc.onicecandidate = (event) => {
      const candidate = event.candidate && event.candidate.toJSON()
      candidate && (addDoc(offerCandidates, { candidate })) // TODO Give Candidat user id
    }

    // Create offer
    const offerDescription = await pc.createOffer()
    await pc.setLocalDescription(offerDescription)

    const offer = {
      sdp: offerDescription.sdp,
      type: offerDescription.type
    }

    await setDoc(doc(rooms, room.id), { offer }, { merge: true })

    // Listen for remote answer
    onSnapshot(room, (doc) => {
      const data = doc.data()
      if (!pc.currentRemoteDescription && data?.answer) {
        const answerDescription = new RTCSessionDescription(data.answer)
        pc.setRemoteDescription(answerDescription)

        EventBus.$emit('SET_STREAM', {
          source: remoteStream,
          name: 'user 1',
          id: remoteStream.id
        })
      }
    })

    // When answered, add candidate to peer connection
    onSnapshot(answerCandidates, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          console.log('ADDING CANDIDATE')
          const candidate = new RTCIceCandidate(change.doc.data().candidate)
          console.log(candidate)
          pc.addIceCandidate(candidate)
        }
      })
    })
    EventBus.$emit('CREATED_ROOM')
    return room.id
  },
  async makeCall ({ state, dispatch, commit }, roomId) {
    commit('setRoomID', roomId)
    const roomDoc = await doc(db, 'rooms', roomId)
    const answerCandidates = collection(roomDoc, 'answerCandidate')

    pc.onicecandidate = async (event) => {
      const candidate = event.candidate && event.candidate.toJSON()
      candidate && addDoc(answerCandidates, { candidate })
    }

    const roomData = (await getDoc(roomDoc)).data()

    if (!roomData) {
      return false
    }

    const offerDescription = roomData.offer
    await pc.setRemoteDescription(new RTCSessionDescription(offerDescription))

    EventBus.$emit('SET_STREAM', {
      source: remoteStream,
      name: 'user 1',
      id: remoteStream.id
    })

    const answerDescription = await pc.createAnswer()
    await pc.setLocalDescription(answerDescription)

    const answer = {
      type: answerDescription.type,
      sdp: answerDescription.sdp
    }

    await updateDoc(roomDoc, { answer })

    onSnapshot(roomDoc, (doc) => {
      if (doc && doc.data() && doc.data().offerCandidate) {
        const candidate = new RTCIceCandidate(doc.data().offerCandidate)
        pc.addIceCandidate(candidate)
      } else {
      }
    })
  },
  /* type:trackType('audio'/'video') */
  // eslint-disable-next-line no-empty-pattern
  async toggleTrack ({}, type) {
    localStream.getTracks().forEach((track) => {
      if (track.kind === type) {
        track.enabled = !track.enabled
      }
    })
  },
  getAllUsers ({ commit, state }) {
    commit('setUsers', [])

    onSnapshot(users, (doc) => {
      const changes = doc.docChanges()
      changes.forEach(change => {
        if (change.type === 'added') {
          const user = {
            ...change.doc.data(),
            id: change.doc.id
          }
          commit('addUser', user)
        }
        if (change.type === 'modified') {
        }
        if (change.type === 'removed') {
          commit('removeUser', change.doc.id)
        }
      })
    })
  },
  waitForOffer () {
    users.onSnapshot(async snapshot => {
      const data = snapshot.data()
      if (!pc.currentRemoteDescription && data.answer) {
        const answer = new RTCSessionDescription(data.answer)
        await pc.setRemoteDescription(answer)
      }
    })
  }
}

const mutations = {
  setUsers: (state, users) => (state.users = users),
  addUser: (state, user) => (state.users.push(user)),
  removeUser: (state, userID) => (state.users = state.users.filter(user => user.id !== userID)),
  setCurrentUser: (state, user) => (state.currentUser = user),
  setOfferCreated: (state, created) => (state.offerCreated = created),
  setRoomID: (state, id) => (state.roomID = id),
  setLocalStream: (state, stream) => (state.localStream = stream),
  setRemoteStream: (state, stream) => (state.remoteStream = stream)
}

const state = window.sessionStorage['bash.radio'] ? JSON.parse(window.sessionStorage['bash.radio']).connection : Object.assign({}, defaultState)

export default {
  state,
  getters,
  actions,
  mutations
}
