import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from './pages/Main'
import Protected from './pages/Protected'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/room/:roomID',
    component: Main,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/protected',
    component: Protected,
    meta: {
      requiresAuth: false
    }
  }
]

export const router = new VueRouter({
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.meta.requiresAuth) {
    if (!localStorage.getItem('seekz_password')) next('/protected')
    else next()
  } else next()
})
