import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import Vue from 'vue'
import peerConnector from './modules/peerConnector'
import protection from './modules/protection'

// Load Vuex
Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: 'bash.radio',
  storage: window.sessionStorage
})

// Create store
export default new Vuex.Store({
  modules: {
    peerConnector,
    protection
  },
  plugins: [vuexPersist.plugin]
})
