<template>
    <v-container class="enter-page">
      <v-card class="enter-card">
        <v-card-text>
          <v-text-field :error="error" type="password" outlined v-model="enteredPassword" label="Password"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined height="56px" width="100%" @click="validateBeforeSubmit">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { router } from '../router'
import { EventBus } from '../event-bus'
const md5 = require('md5')

export default {
  name: 'Protected',
  data () {
    return {
      error: false,
      enteredPassword: null
    }
  },
  computed: {
    ...mapGetters(['password'])
  },
  methods: {
    ...mapMutations(['setPassword']),
    validateBeforeSubmit () {
      const encryptedPassword = md5(this.enteredPassword)
      if (encryptedPassword === 'ca12b3cf6f447cc455010bacf5737e12') {
        this.error = false
        localStorage.setItem('seekz_password', encryptedPassword)
        EventBus.$emit('update')
        router.push('/')
      } else {
        this.error = true
        this.enteredPassword = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.enter-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enter-card {
  padding: 50px;
}
</style>
