<template>
  <div style="background: aqua">
    <Neighbor class="neighbor" v-for="(stream, id) in streams" :key="id" :stream="stream.source" :name="stream.name" :id="id"></Neighbor>
  </div>
</template>

<script>
import { EventBus } from '../../event-bus'
import Neighbor from './Neighbor'

export default {
  name: 'Neighbors',
  components: { Neighbor },
  data () {
    return {
      streams: []
    }
  },
  mounted () {
    EventBus.$on('SET_STREAM', (streamObject) => {
      const neighborObject = {
        source: streamObject.source,
        name: streamObject.name,
        id: streamObject.id
      }
      this.streams.push(neighborObject)
    })
  }
}
</script>

<style lang="scss" scoped>
.neighbor {
  position: absolute;

  &:nth-child(1) {
    left: 5rem;
  }

  &:nth-child(2) {
    right: 3rem;
    bottom: 2rem;
  }
}
</style>
