<template>
  <div>
    <Neighborhood></Neighborhood>
    <v-row justify="center" class="pt-12">
      <v-col cols="12" md="2" >
        <v-btn height="56px" width="100%" @click="createRoom" outlined id="callButton">Offer</v-btn>
      </v-col>
      <v-col cols="9" md="3">
        <v-text-field hide-details :value="roomCreated ? roomID : ''" label="Created Room ID" readonly outlined id="callInput"></v-text-field>
      </v-col>
      <v-col cols="3" md="1">
        <v-btn :disabled="!roomCreated" @click="copyUrl" outlined height="56px" width="100%">
          <v-icon>mdi-clipboard</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12"><v-divider></v-divider></v-col>
      <v-col cols="12" md="4">
        <v-text-field hide-details v-model="callId" label="RoomID" outlined id="callInput"></v-text-field>
        {{callId}}
      </v-col>
      <v-col cols="12" md="2">
        <v-btn height="56px" width="100%" @click="call()" outlined id="answerButton">Enter Room</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Neighborhood from '../components/Neighborhood/Neighborhood'
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '../event-bus'

export default {
  name: 'Main',
  components: {
    Neighborhood
  },
  data: () => ({
    callId: '',
    localStream: '',
    remoteStream: '',
    roomCreated: false
  }),
  computed: {
    ...mapGetters(['offerCreated', 'roomID']),
    roomFromUrl () {
      return this.$route.params.roomID
    }
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.disconnect)
  },
  mounted () {
    this.$nextTick(() => {
      this.getAllUsers()
      this.setup()
    })
    if (this.roomFromUrl) {
      this.makeCall(this.roomFromUrl)
    }
    EventBus.$on('set_local_stream', (stream) => {
      this.localStream = stream
      document.getElementById('localStream').srcObject = stream
    })

    EventBus.$on('set_remote_stream', (stream) => {
      this.$nextTick(() => {
        this.remoteStream = stream
        document.getElementById('remoteStream').srcObject = stream
      })
      // document.getElementById('remoteStream').srcObject.play()
    })

    EventBus.$on('CREATED_ROOM', () => {
      this.roomCreated = true
    })
  },
  watch: {
    roomID (newVal, oldVal) {
      history.pushState(
        {},
        null,
        this.$route.path + '#/room/' + encodeURIComponent(newVal)
      )
    }
  },
  methods: {
    ...mapActions(['setup', 'makeOffer', 'makeCall', 'getAllUsers', 'disconnect']),
    call () {
      this.makeCall(this.callId)
    },
    createRoom () {
      this.makeOffer()
    },
    closeSession () {

    },
    copyUrl () {
      navigator.clipboard.writeText('https://seekz.onrender.com/#/room/' + this.roomID)
    }
  }
}
</script>

<style lang="scss" scoped>
.streams {
  position: absolute;
  left: 0;
  top:0;
  display: flex;
  flex-direction: column;
}
.videoStream {
  width: 20vh;
  height: 20vh;
  border-radius: 10vh;
  object-fit: cover;
  //display: none;
}
</style>
