<template>
  <v-container class="neighborhood">
    <div class="neighborhood__circle">
      <Speak class="neighborhood__speak_btn"></Speak>
    </div>
    <Neighbors class="neighborhood__neighbors"></Neighbors>
  </v-container>
</template>

<script>
import Speak from './Speak'
import Neighbors from './Neighbors'
export default {
  name: 'Neighborhood',
  components: { Neighbors, Speak }
}
</script>

<style lang="scss" scoped>
.neighborhood {
  width: 95vw;
  height: 95vw;
  max-height: 37.5rem;
  max-width: 37.5rem;
  min-height: 17.5rem;
  min-width: 17.5rem;
  border-radius: 50%;
  background: $grey-light;
  box-shadow: $inner-shadow-1;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__speak_btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__neighbors {
    position: absolute;
  }
}
</style>
