<template>
  <v-app>
    <TheHeader :key="updateKey" v-if="showHeader"/>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TheHeader from './components/Header/TheHeader'
import { EventBus } from './event-bus'

export default {
  name: 'App',
  components: {
    TheHeader
  },
  data () {
    return {
      updateKey: 100
    }
  },
  computed: {
    showHeader () {
      return localStorage.getItem('seekz_password')
    }
  },
  mounted () {
    EventBus.$on('update', () => {
      this.$nextTick(() => {
        this.updateKey++
      })
    })
  }
}
</script>

<style scoped lang="scss">

</style>
