const defaultState = {
  password: ''
}

const getters = {
  password: state => state.password
}

const actions = {

}

const mutations = {
  setPassword: (state, value) => {
    state.password = value
  }
}

const state = window.sessionStorage['bash.radio'] ? JSON.parse(window.sessionStorage['bash.radio']).peerConnector : Object.assign({}, defaultState)

export default {
  state,
  getters,
  actions,
  mutations
}
