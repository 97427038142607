// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { collection, getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCBvj7QfATYkxRfx89fI1LnyvtoMz7zIiE',
  authDomain: 'seekz-77994.firebaseapp.com',
  projectId: 'seekz-77994',
  storageBucket: 'seekz-77994.appspot.com',
  messagingSenderId: '366488861024',
  appId: '1:366488861024:web:6522a86dd3ed5441670605'
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)

export const db = getFirestore()

export const users = collection(db, 'users')
export const rooms = collection(db, 'rooms')
