<template>
  <div class="active-users-box active-users-box--inner">
    <v-icon class="active-users-box__icon">mdi-account-group</v-icon>
    {{ usersLength }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserCount',
  computed: {
    ...mapGetters(['usersLength'])
  }
}
</script>

<style lang="scss" scoped>
.active-users-box {
  @include info-box;
  height: fit-content;
  font-weight: bold;
}
</style>
